@import url(https://fonts.googleapis.com/css?family=Press+Start+2P&display=swap);
@import url(https://fonts.googleapis.com/css?family=Kosugi&display=swap);
* {
 padding: 0;
 margin: 0;
 font-family: 'Press Start 2P', cursive;
}

.jp-text {
 font-family: 'Kosugi', cursive;
}

.apps {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}

.nav {
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
 align-items: center;
 height: 68px;
}

.nav-button {
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
 background-color: rgba(255, 255, 2, 0.8);
}

.pusher {
 margin-top: 64px;
}

.p-top {
 margin-top: 32px;
}

.wrap {
 width: 80vw;
}

.zi {
 z-index: -1;
}

.parent {
 display: grid;
 grid-template-columns: repeat(3, 1fr);
 grid-template-rows: repeat(3, 1fr);
 grid-column-gap: 12px;
 grid-row-gap: 12px;
 padding: 0 64px;
}

@media only screen and (max-width: 600px) {
 .wrap {
  width: 95vw;
 }
 .parent {
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  padding: 0 24px;
 }
}
